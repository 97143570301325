<template>
  <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect">
    <template v-for="item in navMenuTree">
      <el-submenu v-if="item.children" :index="item.menuId" :key="item.menuId">
        <template slot="title">
          <i v-if="item.navLevel === 1" class="el-icon-menu"></i>
          <span>{{ item.menuName }}</span>
        </template>
        <recursive-menu v-for="child in item.children" :key="child.menuId" :item="child" />
      </el-submenu>
      <el-menu-item v-else :index="item.menuId" :key="item.menuId">
        <i v-if="item.navLevel === 1" class="el-icon-menu"></i>
        <span>{{ item.menuName }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import recursiveMenu from "./recursiveMenu.vue";
import { mapGetters } from 'vuex';
export default {
  name: "webNavMenu",
  components: {
    recursiveMenu
  },
  props: {
    mode: {
      type: String,
      default: "horizontal"
    }
  },
  computed: {
    ...mapGetters([
      'navMenuList'
    ]),
    navMenuTree() {
      return this.$client.listToTree(this.navMenuList);
    }
  },
  data() {
    return {
      activeIndex: "001",
    };
  },
  methods: {
    handleSelect(val) {
      const navItem = this.navMenuList.find(item => item.menuId === val);
      this.$client.jumpNavFun(navItem);
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-menu-item {
  min-width: unset !important;
}
</style>