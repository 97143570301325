<template>
  <div class="main-box">
    <v-toolbar color="cyan" flat>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <nav-logo></nav-logo>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" absolute left temporary>
      <v-list>
        <div v-for="(item, index) in navMenuTree" :key="index">
          <recursive-menu :item="item" @click="clickHandler"></recursive-menu>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import navLogo from "@components/navLogo";
import recursiveMenu from "./recursiveMenu";
import { mapGetters } from 'vuex';
export default {
  name: "h5NavMenu",
  components: {
    navLogo,
    recursiveMenu
  },
  watch: {
    group() {
      this.drawer = false;
    }
  },
  computed: {
    ...mapGetters([
      'navMenuList'
    ]),
    navMenuTree() {
      return this.$client.listToTree(this.navMenuList);
    }
  },
  data() {
    return {
      group: null,
      drawer: false,
    };
  },
  methods: {
    clickHandler(item) {
      this.$client.jumpNavFun(item);
    }
  },
};
</script>

<style lang="less" scoped>
.main-box {
  background: #ffffff;
}
</style>