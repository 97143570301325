import Http from "./axios";

/**
 * Post 请求
 * @param {string} api
 * @param {Object} param
 * @returns {Promise}
 */
function post(api, param) {
	return new Promise((resolve, reject) => {
		try {
			const result = Http.post(api, param);
			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
}

/**
 * Get 请求
 * @param {string} api 请求的 API 路径
 * @param {Object} params URL 查询参数
 * @returns {Promise} 返回一个 Promise，解析为请求的结果
 */
function get(api, params = {}) {
	return new Promise((resolve, reject) => {
		try {
			const result = Http.get(api, { params }); // 将 params 传递给 axios 的 get 方法
			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
}

export default {
	post,
    get
};
