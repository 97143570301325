/*
 * @Description: 封装拦截器
 * @Author: WenwuLi
 * @Date: 2022-12-31 14:02:20
 * @LastEditors: WenwuLi
 * @LastEditTime: 2023-01-08 15:20:51
 */
import axios from "axios";
import { Message, MessageBox } from "element-ui";
import Router from "@/router/index";
const process = require("@config/index");
const http = axios.create({
	baseURL: process.SERVER.BASE_URL,
	timeout: process.SERVER.TIMEOUT,
});
// let loading = '';
// 请求拦截器
http.interceptors.request.use(
	(config) => {
		// 在发送请求前做一些处理
		console.log("请求拦截器config ===> ", config);
		// 携带token
		const token = sessionStorage.getItem("token");
		if (token) {
			config.headers.Authorization = token;
		}
		return config;
	},
	(error) => {
		// 发送失败
		console.log("请求拦截器error ===> ", error);
		return Promise.reject(error);
	}
);

// 响应拦截器
http.interceptors.response.use(
	(response) => {
		console.log("响应拦截器response ===> ", response);
		if (response.data.head && response.data.head.retCode === "000001") {
			Message({ type: "error", message: response.data.head.retInfo });
		}
		return response;
	},
	(error) => {
		console.log("响应拦截器error ===> ", error);
		if (
			error.response.data.head &&
			error.response.data.head.retCode === "000401"
		) {
			MessageBox.alert("登录超时，请重新登录", "提示", {
				confirmButtonText: "确定",
				type: "error",
				callback: () => {
					Router.push({
						name: "loginPage",
					});
				},
			});
		}
		return Promise.reject(error);
	}
);

export default http;
