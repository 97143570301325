<template>
	<div>
		<v-md-preview :text="markdownContent"></v-md-preview>
	</div>
</template>

<script>
import http from '@js/http.js';

export default {
	name: "detailPage",
	components: {},
	watch: {
		"$route.query.fileName": {
			immediate: true,
			handler() {
				this.init();
			}
		}
	},
	data() {
		return {
			markdownContent: ``,
		};
	},
	methods: {
		async init() {
			const urlParams = this.$client.getUrlParams();
			const { fileName } = urlParams;
			if (fileName) {
				const res = await http.get('/markdown/getMarkdownFile.fun', {
					fileName: `${fileName}.md`
				});
				this.markdownContent = res.data.data;
			}
		}
	},
};
</script>