import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
	navMenuList: [], // 导航栏列表
};

const getters = {
    navMenuList(state) {
        return state.navMenuList;
    }
};

const mutations = {
	setNavMenuList(state, payload) {
		state.navMenuList = payload;
	},
};

const actions = {
	setNavMenuList({ commit }, payload) {
		commit("setNavMenuList", payload);
	},
};

export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
});
